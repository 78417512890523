<template>
	<div v-if="!islogin" id="login" class="login-page">
		<img class="logo" src="../assets/fake_logo.png" alt="logo">
		<div class="form">
			<form class="login-form" @submit.prevent="signIn">
				<input type="text" v-model="username" placeholder="username" />
				<input type="password" v-model="password" placeholder="password" />
				<button @click="signIn">Sign In</button>
				<p class="message"><a href="#">Forgot your password?</a></p>
				<p id="login-message" class="message" @click="this.alertMessage()"></p>
			</form>
		</div>
	</div>
</template>

<script>
import api from '../api/api.js'
import Cookies from 'js-cookie';
export default {
	name: 'LoginPage',
	data() {
		return {
			username: '',
			password: '',
			sid: '',
			islogin: true
		}
	},
	methods: {
		alertMessage(message) {
			document.getElementById("login-message").textContent = message;
		},

		async signIn() {
			try {
				const response = await api.postLogin(this.username, this.password);
				if (response.data[0].attributes.extra.session[0]) {
					//LOGIN SUCCESS
					Cookies.set('session', response.data[0].attributes.extra.session[0].session_id, { expires: 1 });
					Cookies.set('uid', response.data[0].attributes.extra.uid, { expires: 1 });
					this.$router.push({ path: '/home' })
				} else {
					this.alertMessage('Username and password do not match or you do not have an account yet.');
				}
			} catch (error) {
				console.error(error);
			}
		},
	},
	async mounted() {
		this.islogin = await api.loginStatusChecker();
		if (this.islogin) {
			this.$router.push({ path: '/home' })
		}
	}
}
</script>

<style>
#login body {
	background-color: #F3F3F3;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.login-page {
	width: 360px;
	padding: 8% 0 0;
	margin: auto;
}

.form {
	position: relative;
	z-index: 1;
	background: #FFFFFF;
	max-width: 360px;
	margin: 0 auto 100px;
	padding: 45px;
	text-align: center;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
	border-radius: 15px;
}

.form input {
	font-family: "Roboto", sans-serif;
	outline: 0;
	background: #f2f2f2;
	width: 100%;
	border: 0;
	margin: 0 0 15px;
	padding: 15px;
	box-sizing: border-box;
	font-size: 14px;
}

.form button {
	font-family: "Roboto", sans-serif;
	text-transform: uppercase;
	outline: 0;
	background: #EF3B3A;
	width: 100%;
	border: 0;
	padding: 15px;
	color: #FFFFFF;
	font-size: 14px;
	-webkit-transition: all 0.3 ease;
	transition: all 0.3 ease;
	cursor: pointer;
}

.form button:hover,
.form button:active,
.form button:focus {
	background: #ec2e2e;
}

.form .message {
	margin: 15px 0 0;
	color: #b3b3b3;
	font-size: 12px;
}

.form .message a {
	color: #4CAF50;
	text-decoration: none;
}

#login .logo {
	width: 130px;
	height: 130px;
	position: relative;
	left: -270px;
}
</style>