<template>
    <div class="user-info-popup" @click="$emit('toggleUserInfoPopup')">
        <transition name="dropdown">
            <div class="popup-content" v-if="isShowPopup">
                <img class="user-img" src="@/assets/fake_user_img.png">
                <div class="user-email">{{ userEmail }}</div>
                <div v-if="userDept" class="user-dept">{{ userDept }}</div>
                <div v-if="userLevel < 4" class="user-level">Level {{ userLevel }}</div>
                <div v-if="userLevel == 4" class="user-level">C-Level</div>
                <button class="button" @click.stop="logout()">Sign Out</button>
            </div>
        </transition>
    </div>
</template>

<script>
import api from '../../api/api.js';
export default {
    name: 'userInfo',
    props: {
        isShowPopup: { type: Boolean, default: false },
        userEmail: { type: String, default: 'missing email' },
        userDept: { type: String, default: 'missing department' },
        userLevel: { type: String, default: 'missing level' }
    },
    data() {
        return {
        };
    },

    methods: {
        async logout() {
            const response = await api.logout();
            if (!response.error) {
                this.$router.push({ path: '/' });
            } else {
                console.error(response);
            }
        },
    },


}
</script>

<style scoped>
.popup-content {
    position: absolute;
    width: 200px;
    background-color: #E6E6E6;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    justify-content: center;
    padding: 25px 5px;
    border: 1px solid lightgray;
}

.popup-content .user-img {
    height: 100px;
    border-radius: 100px;
    margin-bottom: 15px;
}

.popup-content .user-email {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.popup-content .user-dept {
    font-size: 14px;
    margin-bottom: 10px;
    background-color: crimson;
    color: white;
    padding: 3px 0px;
    border-radius: 100px;
    width: 80%;
}

.popup-content .user-level {
    font-size: 14px;
    margin-bottom: 20px;
    background-color: crimson;
    color: white;
    padding: 3px 0px;
    border-radius: 100px;
    width: 80%;
}

.popup-content .button {
    width: 90%;
    height: 40px;
    background-color: #FF0000;
    color: #FFFFFF;
    font-size: 16px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid darkgray;
}

.popup-content .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

.dropdown-enter-active,
.dropdown-leave-active {
    transition: all 1s;
}

.dropdown-enter-from,
.dropdown-leave-to {
    opacity: 0;
    transform: translateY(30px);
}
</style>