<template>
    <div class="upload-document">
        <transition name="popup">
            <div class="popup-mask" v-if="isShowPopup == true">
                <div class="popup-content">
                    <i class="close bi bi-x-circle-fill" @click="clearFile()"></i>
                    <div class="title">Upload new document</div>
                    <div class="content">
                        <div ref="drag" class="drag" v-show="Object.getOwnPropertyNames(fileData).length == 0">
                            <div>
                                <div class="drag-icon-box">
                                    <i class="bi bi-cloud-arrow-up"></i>
                                </div>
                                <div class="drag-message">
                                    <span class="drag-message-title">Drag and drop an image here</span>
                                    <p>or</p>
                                    <label class="drag-message-label">
                                        <input id="upload-bar-input" type="file" accept="application/pdf"
                                            style="display: none;" @change="onFileChanged()">
                                        <label for="upload-bar-input">
                                            <div class="drag-message-manual">Select a file</div>
                                        </label>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-show="Object.getOwnPropertyNames(fileData).length != 0" class="file-info">
                            <div class="file-detail">
                                <div id="upload-bar-preview" class="upload-bar-preview">Empty File Input</div>
                                <div class="ai-generate">
                                    <p>AI-Recommended Title and Description</p><button class="ai-generate-button"
                                        @click="aiGenerateTitleDescription(this.pdfText)">
                                        <div v-if="!isTitleAiLoading"><i
                                                class="bi bi-star-fill"></i>&nbsp;&nbsp;&nbsp;AI suggest</div>
                                        <div class="loading" v-if="isTitleAiLoading"></div>
                                    </button>
                                </div>
                                <div class="file-detail-title">
                                    <h6>Title:</h6>
                                    <input type="text" v-model="fileTitle" />
                                </div>
                                <div class="file-detail-description">
                                    <h6>Description:</h6>
                                    <div class="file-detail-description-textarea"><textarea class="scrollbar"
                                            type="text" rows="4" v-model="fileDescription"></textarea></div>
                                </div>
                                <div class="file-detail-privacy">
                                    <h6>Set to private</h6>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="file-detail-privacy-input"
                                            v-model="privateFlag">
                                    </div>
                                </div>
                                <div v-if="!privateFlag">
                                    <div class="file-detail-permission">
                                        <h6>Permission setting</h6>
                                        <div class="ai-generate">
                                            <p>AI-recommended permission levels</p><button class="ai-generate-button"
                                                @click="aiGenerateAccessGroup(this.pdfText)">
                                                <div v-if="!isGroupAiLoading"><i
                                                        class="bi bi-star-fill"></i>&nbsp;&nbsp;&nbsp;AI suggest
                                                </div>
                                                <div class="loading" v-if="isGroupAiLoading"></div>
                                            </button>
                                        </div>
                                        <div class="ai-permission-reason-outer" v-if="permissionReason">
                                            <div class="ai-permission-reason scrollbar">{{ permissionReason }}</div>
                                        </div>
                                        <div class="flex-space-between">
                                            <div>
                                                <select id="group-dept" v-model="selectedGroupDept">
                                                    <option v-for="group in groupList" :key="group.id">{{ group.dept }}
                                                    </option>
                                                </select>
                                                <select id="group-level" v-model="selectedGroupLevel">
                                                    <option v-for="level in availableGroupLevels" :key="level"
                                                        :value="level">
                                                        Level {{ level }}
                                                    </option>
                                                </select>
                                            </div>
                                            <button class="button add-button" @click="addPermission()">+</button>
                                        </div>
                                    </div>
                                    <div class="file-detail-perimission-list">
                                        <h6>Current Permission List:</h6>
                                        <ul>
                                            <li class="item" v-for="item in currentGroupList" :key="item.id">{{
                item.dept }} Level {{ item.lvl }} <i class="bi bi-x-lg"
                                                    @click="removePermission(item)"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="button-list">
                                    <div :class="{ 'button': true, 'disable-button': this.isUploading }"
                                        @click="submitFile(fileData)">Save</div>
                                    <div class="button cancel-button" @click="clearFile()">Cancel</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import api from '../../api/api.js';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
    name: 'uploadDocument',
    props: {
    },
    data() {
        return {
            isShowPopup: true,
            groupList: [],
            dragFile: {},
            fileType: '',

            fileData: {},
            fileName: '',
            mediaTypes: '',
            fileContent: '',
            fileTitle: '',
            fileDescription: '',
            privateFlag: false,
            currentGroupList: [],
            permissionReason: '',

            pdfText: '',

            messageBox: null,
            inputBox: null,

            selectedGroupDept: null,
            selectedGroupLevel: null,

            isTitleAiLoading: false,
            isGroupAiLoading: false,
            isUploading: false,
        };
    },
    methods: {
        onFileChanged() {
            if (this.inputBox.files.length > 0) {
                if (this.checkFileSize(this.inputBox)) {
                    this.messageBox.textContent = this.inputBox.files[0].name;
                    this.onSelectChange(this.inputBox);
                } else {
                    this.messageBox.textContent = "Max file size is 6MB";
                    this.fileData = {};
                }
            } else if (this.dragFile.files.length > 0) {
                if (this.checkFileSize(this.dragFile)) {
                    this.messageBox.textContent = this.dragFile.files[0].name;
                    this.onSelectChange(this.dragFile);
                } else {
                    this.messageBox.textContent = "Max file size is 6MB";
                    this.fileData = {};
                }
            } else {
                this.messageBox.textContent = "No file selected";
                this.fileData = {};
            }
        },

        checkFileSize(input) {
            if (input.files && input.files[0]) {
                var fileSize = input.files[0].size;
                var maxSize = 1024 * 1024 * 6; // 6MB

                if (fileSize > maxSize) {
                    alert("Max file size is 6MB!");
                    input.value = "";
                    return false;
                }
                return true;
            }
        },

        onSelectChange(ev) {
            if (ev.files.length > 0) {
                this.fileType = ev.files[0].type;
                if (this.fileType === "application/pdf") {
                    this.mediaTypes = "application/pdf";
                } else {
                    this.fileType = '';
                    this.messageBox.textContent = "The file type is not supported";
                    return;
                }
                this.fileName = ev.files[0].name;
                this.extractTextFromPdf(ev.files[0]);
                let reader = new FileReader();
                reader.readAsDataURL(ev.files[0]);
                reader.onloadend = rev => {
                    this.fileContent = rev.target.result.replace('data:', '').replace(/^.+,/, '');
                    const data = {
                        "name": this.fileName,
                        "mediatypes": this.mediaTypes,
                        "content": this.fileContent
                    };
                    this.fileData = data;
                    this.inputBox.value = '';
                };
            } else {
                console.log("no file selected");
            }
        },

        addPermission() {
            if (this.selectedGroupDept && this.selectedGroupLevel) {
                const alreadyExists = this.currentGroupList.some(item =>
                    item.dept === this.selectedGroupDept && item.lvl === this.selectedGroupLevel
                );
                if (!alreadyExists) {
                    this.currentGroupList.push({
                        dept: this.selectedGroupDept,
                        lvl: this.selectedGroupLevel,
                    })
                }
            }
            this.removeSameDeptGroup();
        },

        removeSameDeptGroup() {
            this.currentGroupList = Object.values(this.currentGroupList.reduce((acc, item) => {
                acc[item.dept] = item;
                return acc;
            }, {}));
        },

        removePermission(item) {
            this.currentGroupList = this.currentGroupList.filter(i => i !== item);
        },

        clearFile() {
            this.fileData = {};
            this.fileContent = '';
            this.fileName = '';
            this.fileType = '';
            this.mediaTypes = '';
            this.fileTitle = '';
            this.fileDescription = '';
            this.currentGroupList = [];
            this.selectedGroupDept = '';
            this.selectedGroupLevel = '';
            this.privateFlag = false;
            this.inputBox.value = '';
            this.pdfText = '';
            this.permissionReason = '';
            this.messageBox.textContent = "No file selected";

            this.closePopup();
        },

        closePopup() {
            this.isShowPopup = false;
            this.$emit('toggleUploadPopup');
        },

        async submitFile() {
            if (!this.fileTitle) {
                alert("File title should not be empty");
                return;
            }
            if (!this.privateFlag && this.currentGroupList.length == 0) {
                alert("Please select at least one group");
                return;
            }
            if (this.isUploading) {
                return;
            }
            this.isUploading = true;
            const data = {
                "base64Content": this.fileContent,
                "fileType": this.mediaTypes,
                "fileName": this.fileName,
                "fileTitle": this.fileTitle,
                "fileDescription": this.fileDescription,
                "fileAccessGroups": this.privateFlag ? [] : this.currentGroupList,
                "privateFlag": this.privateFlag ? 1 : 0,
            };
            const response = await api.postUploadFile(data);
            if (!response.error) {
                // this.$router.go(0);
                this.isUploading = false;
                this.$emit('refreshList');
                this.closePopup();
            } else {
                console.error(response);
            }
        },

        bindEvents() {
            const drag = this.$refs.drag
            drag.addEventListener('dragover', e => {
                e.preventDefault();
                drag.style.borderColor = 'red';
            })
            drag.addEventListener('dragleave', e => {
                e.preventDefault();
                drag.style.borderColor = '#eee';
            })
            drag.addEventListener('drop', e => {
                e.preventDefault();
                drag.style.borderColor = '#eee';
                this.dragFile = e.dataTransfer;
                this.onFileChanged();
            })
        },

        async getAceessGroupList() {
            const response = await api.getDocumentAceessGroupList();
            if (!response.error && response.data[0].attributes.extra.ricoh) {
                this.groupList = response.data[0].attributes.extra.ricoh.result;

                const uniqueGroups = this.groupList.reduce((acc, item) => {
                    const existingGroup = acc.find(group => group.dept === item.group_dept);
                    if (existingGroup) {
                        existingGroup.lvl.push(item.group_level);
                    } else {
                        acc.push({ dept: item.group_dept, lvl: [item.group_level] });
                    }
                    return acc;
                }, []);
                this.groupList = uniqueGroups;
            }
        },

        async aiGenerateTitleDescription(prompt = '') {
            if (this.isTitleAiLoading == true) {
                return;
            }
            this.isTitleAiLoading = true;
            let response;
            if (prompt === '') {
                response = await api.postAskTitleDescription("help me to generate another title and some realtive description, reply me a json string only");
            } else {
                response = await api.postAskTitleDescription(prompt);
            }
            this.isTitleAiLoading = false;
            let result;
            if (!response.error) {
                try {
                    // result = JSON.parse("["+response.data[0].attributes.extra.ricoh.result.content.replace(/'/g, '"')+"]")[0];
                    const jsonString = response.data[0].attributes.extra.ricoh.result.content.match(/```json({.*})```|({.*})/s)[0];
                    result = JSON.parse("[" + jsonString + "]")[0];
                }
                catch (error) {
                    console.error('JSON parse error');
                }
            } else {
                console.error(response);
            }
            if (result) {
                if (result.title) this.fileTitle = result.title;
                if (result.description) this.fileDescription = result.description;
            } else {
                alert('Failed to generate title and description. Please try again.');
            }
        },

        async aiGenerateAccessGroup(prompt = '', retry = 0) {
            if (this.isGroupAiLoading == true) {
                return;
            }
            this.isGroupAiLoading = true;
            this.permissionReason = '';
            let response;
            if (prompt === '') {
                response = await api.postAskAccessGroup("help me to generate some different access group. Reply me a json string only. The json should contain a 'dept' field and a 'lvl' field. The 'dept' field should be a string and the 'lvl' field should be a number. For example, the reply should be like this: [{\"dept\": \"HR\", \"lvl\": 1},{\"dept\": \"HR\", \"lvl\": 2},{\"dept\": \"IT\", \"lvl\": 1}]. The maximuim length of the array is 5. The maximuim lvl is 3. Each item should only has one department and one lvl. Reply me a json string only. Your reply should not contain any other text or information.");
            } else {
                response = await api.postAskAccessGroup(prompt);
            }
            this.isGroupAiLoading = false;
            let result;
            if (!response.error) {
                try {
                    // result = JSON.parse(response.data[0].attributes.extra.ricoh.result.content.replace(/'/g, '"'));
                    const jsonString = response.data[0].attributes.extra.ricoh.result.content.match(/```json({.*})```|({.*})/s)[0];
                    result = JSON.parse("[" + jsonString + "]")[0];
                }
                catch (error) {
                    console.error('JSON parse error');
                }
            } else {
                console.error(response);
            }
            if (result) {
                const filterResult = this.groupList
                    .filter(item => result.access_departments.includes(item.dept) && item.lvl.includes(parseInt(result.access_level)))
                    .map(item => ({ dept: item.dept, lvl: result.access_level }));
                if (filterResult.length > 0) {
                    filterResult.forEach(newMatch => {
                        const alreadyExists = this.currentGroupList.some(item =>
                            item.dept === newMatch.dept && item.lvl === newMatch.lvl
                        );
                        if (!alreadyExists) {
                            this.currentGroupList.push(newMatch);
                        }
                    });
                    this.removeSameDeptGroup();
                    this.permissionReason = result.reason;
                } else {
                    if (retry < 3) {
                        console.log('No new matching access group found. Trying. Automatically regenerating ' + (retry + 1) + '...');
                        this.aiGenerateAccessGroup(prompt, retry + 1);
                    } else {
                        alert('No new matching access group found. You can try to generate again.');
                    }
                }
            }
        },

        async extractTextFromPdf(file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const arrayBuffer = e.target.result;
                const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
                let textContent = '';

                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const content = await page.getTextContent();
                    content.items.forEach(item => {
                        textContent += item.str + ' ';
                    });
                }

                this.pdfText = textContent.trim();
                this.aiGenerateTitleDescription(this.pdfText);
                this.aiGenerateAccessGroup(this.pdfText);
            };
            reader.readAsArrayBuffer(file);
        },
    },

    computed: {
        availableGroupLevels() {
            if (this.selectedGroupDept !== null) {
                const selectedGroup = this.groupList.find(
                    (group) => group.dept === this.selectedGroupDept
                );
                return selectedGroup ? selectedGroup.lvl : [];
            }
            return [];
        },
    },

    mounted() {
        this.bindEvents();
        this.messageBox = document.querySelector("#upload-bar-preview");
        this.inputBox = document.querySelector("#upload-bar-input");
        this.getAceessGroupList();
    }
}
</script>

<style scoped>
.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.popup-mask .popup-content {
    position: absolute;
    width: 30%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    border-radius: 10px;
    padding: 20px;
    min-width: 530px;
    max-height: 100%;
    overflow-y: scroll;
}

.popup-mask .popup-content::-webkit-scrollbar {
    display: none;
}

.popup-mask .popup-content .close {
    border-radius: 40px;
    width: 18px;
    font-size: 20px;
    float: right;
    color: gray;
    position: absolute;
    right: 23px;
    top: 13px;
    cursor: pointer;
}

.popup-mask .popup-content .title {
    margin: 20px 0;
    font-weight: bold;
    font-size: 15px;
}

.drag {
    width: 90%;
    height: 300px;
    border: 2px dashed;
    border-color: #a3a3a3;
    border-radius: 5px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: lightgray;
}

.drag .drag-icon-box {
    width: 100%;
    height: 60px;
    text-align: center;
    font-size: 50px;
    line-height: 60px;
    color: #606266;
}

.drag .drag-message {
    width: 100%;
    line-height: 30px;
    text-align: center;
}

.drag .drag-message .drag-message-title {
    font-size: 14px;
    color: #606266;
    font-weight: bold;
}

.drag .drag-message p {
    margin-bottom: 5px;
    font-weight: lighter;
}

.drag .drag-message .drag-message-label {
    height: auto;
    position: relative;
    overflow: hidden;
}

.drag .drag-message .drag-message-label .drag-message-input {
    position: absolute;
    left: -100px;
    top: -100px;
    z-index: -1;
    display: none;
}

.drag .drag-message .drag-message-label .drag-message-manual {
    border-radius: 10px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    background-color: crimson;
    padding: 0 40px;
    border: 1px solid black;
}

.content .file-detail .upload-bar-preview {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: dodgerblue;
}

.content .file-detail .button {
    border-radius: 3px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    background-color: crimson;
    padding: 0px 10px;
    border: 1px solid black;
    width: 20%;
    margin: 5px 5px;
    line-height: 35px;
}

.content .file-detail .button:hover {
    background-color: firebrick;
}

.content .file-detail .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .button.disable-button {
    background-color: darkgray;
    cursor: default;
}

.content .file-detail .button.cancel-button {
    background-color: darkgray;
}

.content .file-info {
    width: 100%;
    position: relative;
    top: -15px;
    padding: 0 10px;
}

.content .file-detail h6 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.content .file-detail .file-detail-title {
    text-align: left;
}

.content .file-detail .file-detail-title input {
    width: 100%;
    border: 1px solid black;
    border-radius: 6px;
    padding: 10px 15px;
    font-size: 14px;
}

.content .file-detail .file-detail-description {
    margin-top: 15px;
    text-align: left;
}

.content .file-detail .file-detail-description .file-detail-description-textarea {
    width: 100%;
    border: 1px solid black;
    border-radius: 6px;
    padding: 5px 0px;
    font-size: 14px;
    display: flex;
}

.content .file-detail .file-detail-description .file-detail-description-textarea textarea {
    width: 100%;
    border: none;
    resize: none;
    outline: none;
    padding: 5px 15px;
}

.content .file-detail .file-detail-privacy {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 10px 0;
}

.content .file-detail .file-detail-privacy .form-check-input:checked {
    background-color: crimson;
    border-color: crimson;
}

.content .file-detail .file-detail-permission {
    text-align: left;
    font-size: 12px;
}

.content .file-detail .file-detail-permission .ai-permission-reason-outer {
    margin: 4px 0 12px 0;
    border-radius: 5px;
    background-color: gainsboro;
    padding: 5px 15px;
}

.content .file-detail .file-detail-permission .ai-permission-reason {
    overflow-y: scroll;
    max-height: 4.2em;
    line-height: 1.4em;
}

.content .file-detail .file-detail-permission select {
    margin: 0 10px 0 0;
    min-width: 150px;
    border: 2px solid black;
    border-radius: 3px;
}

.content .file-detail .file-detail-permission .add-button {
    padding: 0;
    width: 115px;
    margin: 0;
    line-height: normal;
    border-radius: 20px;
}

.content .file-detail .file-detail-perimission-list {
    text-align: left;
    margin-top: 20px;
}

.content .file-detail .file-detail-perimission-list ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.content .file-detail .file-detail-perimission-list ul li {
    list-style-type: none;
    background-color: pink;
    margin: 0 5px 5px 5px;
    border-radius: 20px;
    padding: 0 15px;
    color: red;
    border: 1px solid red;
    font-size: 12px;
    font-weight: bold;
}

.content .file-detail .file-detail-perimission-list ul li i {
    cursor: pointer;
}

.content .file-detail .button-list {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.content .file-detail .ai-generate {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    flex-wrap: wrap;
    align-items: center;
}

.content .file-detail .ai-generate p {
    margin: 0;
    text-align: left;
    font-size: 12px;
    width: 60%;
}

.content .file-detail .ai-generate .ai-generate-button {
    border-radius: 20px;
    background-color: crimson;
    color: white;
    padding: 0 10px;
    width: 115px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
}

.content .file-detail .ai-generate .ai-generate-button:hover {
    background-color: firebrick;
}

.content .file-detail .ai-generate .ai-generate-button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.loading {
    position: relative;
    width: 20px;
    height: 20px;
}
</style>